import API from './axiosConfig';

function buildResponse(response) {
  return response;
}

export default {
  postDataWithoutAuth: async (url, body) => {
    return API.post(`${url}`, body)
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
  getDataWithoutAuth: async (url, parameter) => {
    return API.get(`${url}`, {
      params: parameter,
    })
      .then((response) => buildResponse(response))
      .catch((err) => buildResponse(err.response ?? null));
  },
};
