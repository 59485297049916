import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Form, FormControl, Button } from 'react-bootstrap';
import backdrop from '../../assets/images/backdrop.webp';
import titipLelang from '../../assets/images/titipLelang.webp';
import CardReview from '../../components/Cards/CardReview';
import CarouselMultipleLayout from '../../containers/Layouts/CarouselLayout/CarouselMultipleLayout';
import toast from 'react-hot-toast';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import withoutAuth from '../../services/withoutAuthProvider';
import withAuth from '../../services/withAuthProvider';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { userSelector } from '../../redux/auth.slice';
import { prosesIcon1, prosesIcon2, prosesIcon3, testi1, testi2, testi3 } from '../../assets/images/testimoni';
// import { TitipL1, TitipL2, TitipL3 } from '../../assets/icons/TitipLelangIcons'

const why_caready = [
  {
    // icon: TitipL1,
    image: prosesIcon2,
    title: 'Terpercaya dan aman',
    value:
      'PT Balai Lelang Caready berkomitmen dan berdedikasi untuk menjadi yang terpercaya dan aman dalam proses lelang bagi penguna bidder dan lainnya.',
  },
  {
    // icon: TitipL2,
    image: prosesIcon1,
    title: 'Proses mudah dan cepat',
    value:
      'Caready memberikan pelayanan proses yang mudah dan cepat dengan secara online dari pendaftaran keikutsertaan serta pembayaran lelang.',
  },
  {
    // icon: TitipL3,
    image: prosesIcon3,
    title: 'Varian kendaraan yang sangat banyak',
    value:
      'Caready menyediakan segala unit kendaraan dan barang dari mobil, truck , alat berat dan barang elektronik dengan varian yang banyak dan stok melimpah.',
  },
];

const testimonee = [
  {
    image: testi1,
    title: 'Rio Ferdianto',
    value: 'BPKB ready cepat dan aman. Gak usah takut ikut lelang karena unitnya terpercaya semua.',
  },
  {
    image: testi2,
    title: 'Dani',
    value: 'Lokasi lelang yang luas, bersih, staff ramah. Semoga unitnya bisa bertambah banyak dan bervariasi lagi.',
  },
  {
    image: testi3,
    title: 'Adi Sucipto',
    value: 'Semua jenis kendaraan ada di sini siapa yg mau memiliki kendaraan roda dua atau empat silakan datang',
  },
];
const pengalamanTitipLelang = [
  {
    title: 'DAFTARKAN DIRI ANDA',
    desc: 'CAREADY memberikan kemudahan  para customer untuk mengikuti Lelang dari caready Kini Customer bisa mendaftarkan secara online siapkan email aktif dan data diri lengkap',
  },
  {
    title: 'INPEKSI MOBIL',
    desc: 'Ketahui kondisi kendaraan incaran kalian di caready dengan staf yang berpengalaman. Caready melakukan pengecekan secara objektif menggunakan system inpeksi kendaraan. Hasil inpeksi kendaraan bisa dilihat secara detail secara transparan , akurat, dan detail.',
  },
  {
    title: 'LELANG MOBIL ANDA',
    desc: 'Hallo Team caready! Mau jual mobil lama kamu? Bisa titip Lelang di caready. Ada ratusan calon pembeli yang akan menawar mobil anda. Proses cepat, aman dan mudah',
  },
  {
    title: 'DAPATKAN PEMBAYARAN',
    desc: 'Dengan pembayaran transaksi di CAREADY lebih aman dan mudah dikarenakan menggunakan virtual account (VA)',
  },
];

export default function TitipLelang() {
  const [dataCategories, setDataCategory] = useState([]);
  const [dataBranch, setDataBranch] = useState([]);
  const isLogged = useSelector((state) => state.authReducer.isLogged);
  const { push } = useHistory();
  const user = useSelector(userSelector);

  const getCategories = async () => {
    const response = await withoutAuth.getDataWithoutAuth('/api/unit-type', {});
    if (response?.status === 200) {
      setDataCategory(response.data.data.data);
    } else {
      toast.error(response?.data?.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  const getBranch = async () => {
    const response = await withoutAuth.getDataWithoutAuth('/api/location', {});
    if (response?.status === 200) {
      setDataBranch(response.data.data.data);
    } else {
      toast.error(response?.data?.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  useEffect(() => {
    getBranch();
    getCategories();
    reset({ name: user?.name, email: user?.email, phone: user?.phone });
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    unit_type_id: Yup.string().required('Silahkan memilih kategori lelang'),
    location_id: Yup.string().required('Silahkan memilih cabang'),
    name: Yup.string().required('Silahkan mengisi nama lengkap'),
    email: Yup.string().required('Silahkan mengisi e-mail'),
    phone: Yup.string().required('Silahkan mengisi telepon'),
  });

  // functions to build form returned by useForm() hook
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = async (data) => {
    const dataTitipLelang = new FormData();

    dataTitipLelang.append('unit_type_id', data.unit_type_id);
    dataTitipLelang.append('location_id', data.location_id);
    dataTitipLelang.append('name', data.name);
    dataTitipLelang.append('email', data.email);
    dataTitipLelang.append('phone', data.phone);

    const loading = toast.loading('Mengirimkan...');
    const response = await withAuth.postDataWithAuth('/api/entrust_request', dataTitipLelang);
    if (response?.status === 200) {
      toast.dismiss(loading);
      toast.success(response.data.message, {
        id: 'success-message',
        duration: 3000,
      });
      reset({ name: user?.name, email: user?.email, phone: user?.phone, unit_type_id: null, location_id: null });
    } else {
      toast.dismiss(loading);
      toast.error(response.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  return (
    <>
      <section className="cover-background position-relative" style={{ background: `url(${backdrop})` }}>
        <div className="background-overlay"></div>
        <Container>
          <Row>
            <Col md={isLogged ? 9 : 12}>
              <div
                className={`titip-lelang ${isLogged ? 'center-vertical text-left' : 'center-horizontal-vertical flex-column text-center'}`}
                style={isLogged ? {} : { minHeight: '620px' }}
              >
                {isLogged ? (
                  <h2 className={`titip-lelang-title ${isLogged ? 'text-left' : ''}`}>
                    Percayakan urusan lelang
                    <br /> kendaraan Anda pada Caready!
                  </h2>
                ) : (
                  <>
                    <h2 className={`titip-lelang-title ${isLogged ? '' : 'text-center'}`}>
                      Percayakan urusan lelang kendaraan Anda <br />
                      pada Caready!
                    </h2>
                    <Button
                      className="button-login button-login-titip-lelang mb-3"
                      variant="primary"
                      type="button"
                      onClick={() => push('/masuk')}
                    >
                      Masuk Untuk Mulai Titip Lelang
                    </Button>
                  </>
                )}
              </div>
            </Col>

            {isLogged ? (
              <Col md={3}>
                <div className="box-border titip-lelang-form">
                  <div className="header mb-2">
                    <span className="title">Titip Lelang</span>
                    <br />
                  </div>
                  <div className="body">
                    <Form className="form-caready" onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label className="required">Kategori Lelang</Form.Label>
                        <Form.Select placeholder="Pilih Kategori Lelang" {...register('unit_type_id')}>
                          <option value="">Pilih Kategori Lelang</option>
                          {dataCategories.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.unit_type_id?.message && <span className="error-message">{errors.unit_type_id?.message}</span>}
                      </Form.Group>

                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label className="required">Cabang</Form.Label>
                        <Form.Select placeholder="Pilih Cabang" {...register('location_id')}>
                          <option value="">Pilih Cabang</option>
                          {dataBranch.map((branch) => (
                            <option key={branch.id} value={branch.id}>
                              {branch.name}
                            </option>
                          ))}
                        </Form.Select>
                        {errors.location_id?.message && <span className="error-message">{errors.location_id?.message}</span>}
                      </Form.Group>

                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label className="required">Nama Lengkap</Form.Label>
                        <FormControl
                          placeholder="Nama Lengkap"
                          aria-label="Nama Lengkap"
                          aria-describedby="basic-addon1"
                          {...register('name')}
                        />
                        {errors.name?.message && <span className="error-message">{errors.name?.message}</span>}
                      </Form.Group>

                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label className="required">E-mail</Form.Label>
                        <FormControl placeholder="E-mail" aria-label="E-mail" aria-describedby="basic-addon1" {...register('email')} />
                        {errors.email?.message && <span className="error-message">{errors.email?.message}</span>}
                      </Form.Group>

                      <Form.Group className="form-group" controlId="formBasicEmail">
                        <Form.Label className="required">Telepon</Form.Label>
                        <FormControl placeholder="Telepon" aria-label="Telepon" aria-describedby="basic-addon1" {...register('phone')} />
                        {errors.phone?.message && <span className="error-message">{errors.phone?.message}</span>}
                      </Form.Group>

                      <Button
                        className="button-login mb-3"
                        style={{ display: 'block !important', width: '100%' }}
                        variant="primary"
                        type="submit"
                      >
                        Selanjutnya
                      </Button>
                    </Form>
                  </div>
                </div>
              </Col>
            ) : (
              ''
            )}
          </Row>
        </Container>
      </section>
      <section className="tahapan-lelang">
        <Container>
          <Row>
            <Col md={{ span: 12 }}>
              <h2 className="title text-center" style={{ marginBottom: '50px' }}>
                Pengalaman Lelang Mobil Anda Jadi Bebas Ribet
              </h2>
            </Col>
            {pengalamanTitipLelang.map((peng, index) => (
              <Col md={{ span: 3 }} key={`pengalaman-${index}`}>
                <div className="cards h-100">
                  <div className="number">{index + 1}</div>
                  <br />
                  <div className="mt-15" style={{ padding: '24px' }}>
                    <div className="title">{peng.title}</div>
                    <div className="desc">{peng.desc}</div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </section>
      <section className="kelebihan">
        <Container>
          <Row>
            <Col md={{ span: 6 }}>
              <div className="kelebihan-left">
                <h3 className="title">Kenapa Pilih Caready?</h3>
                {why_caready.map(({ title, value, image }, idx) => (
                  <div className="d-flex" style={{ marginBottom: '20px' }} key={`why-${idx}`}>
                    <div>
                      <div className="bullet d-flex flex-center h-100 w-100">
                        <img src={image} alt="" className="h-75 w-75 rounded-circle" />
                      </div>
                    </div>
                    <div style={{ paddingLeft: '20px' }}>
                      <div className="title">{title}</div>
                      <div className="desc">{value}</div>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col md={{ span: 6 }}>
              <div className="kelebihan-right center-horizontal-vertical">
                <div className="image">
                  <img src={titipLelang} alt="" className="h-100 w-100" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="counter">
        <Container>
          <Row>
            <Col md={{ span: 4 }}>
              <div className="counter-item">
                <div className="number">+75.000</div>
                <div className="desc">Transaksi di Tahun 2022</div>
              </div>
            </Col>
            <Col md={{ span: 4 }}>
              <div className="counter-item">
                <div className="number">5</div>
                <div className="desc">Cabang Caready</div>
              </div>
            </Col>
            <Col md={{ span: 4 }}>
              <div className="counter-item">
                <div className="number">+180.000</div>
                <div className="desc">Total Unit Lelang</div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="reviews" style={{ paddingTop: '100px', paddingBottom: '100px' }}>
        <Container>
          <Row>
            <Col md={{ span: '12' }}>
              <div className="title text-center">Anda juga bisa seperti mereka !</div>
              <div className="tagline text-center">Percayakan urusan lelang kendaraan lama Anda pada Caready.</div>
            </Col>
            <Col></Col>
            <CarouselMultipleLayout totalItem={4}>
              {testimonee.map((test, idx) => (
                <Col md={{ span: '4' }} key={`testi-${idx}`}>
                  <CardReview name={test.title} review={test.value} image={test.image} />
                </Col>
              ))}
            </CarouselMultipleLayout>
          </Row>
        </Container>
      </section>
    </>
  );
}
