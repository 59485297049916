import { format } from 'date-fns';
import SingleUnit from '../../assets/images/single-unit.png';
import imageCar from '../../assets/images/category-car.png';
import Location from '../../assets/icons/Location';
import Time from '../../assets/icons/Time';
import CarouselLayout from '../../containers/Layouts/CarouselLayout/CarouselLayout';
import { StampLabel } from '../../components/StampLabel/StampLabel';
import ArtworkPending from '../LiveAuction/ArtworkPending';
import PageNotFound from '../Errors/PageNotFound';
import { useState, useEffect } from 'react';
import ResultLelang from '../../components/Modals/ResultLelang';
import withoutAuthProvider from '../../services/withoutAuthProvider';
import Countdown, { zeroPad, calcTimeDelta, formatTimeDelta } from 'react-countdown';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import logo from '../../assets/images/caready-logo.webp';

const defaultImage =
  'https://st3.depositphotos.com/23594922/31822/v/380/depositphotos_318221368-stock-illustration-missing-picture-page-for-website.jpg?forcejpeg=true';

const initialDataModal = {
  lot_number: '',
  data_unit: {},
};

const initialBid = {
  offer_price: 0,
  type: '-',
};

const LiveAuctionPublic = () => {
  const { id } = useParams();
  const [isOpen, setIsOpen] = useState(false);
  const [result, setResult] = useState('LOADING');
  const [liveAuction, setLiveAuction] = useState('STARTED');
  const [dataModal, setDataModal] = useState({ ...initialDataModal });
  const [key, setKey] = useState(1);

  // list
  const [data, setData] = useState({});
  const [auction, setAuction] = useState({});
  // const [modalWinner, setModalWinner] = useState({})
  const [images, setImages] = useState([]);
  // const [nextLots, setNextLots] = useState([]);
  const [biddingList, setBiddingList] = useState([]);

  // bid
  const [highestBid, setHighestBid] = useState({ ...initialBid });
  const [counter, setCounter] = useState(null);

  // FETCH DATA V2
  const getDetailAuction = async () => {
    const params = {};
    const response = await withoutAuthProvider.getDataWithoutAuth(`/api/v2/public/detail-auction/${id}`, params);
    if (response?.status === 200) {
      const dataAuction = response.data.data;
      // console.log('ini auction', dataAuction)
      setAuction(dataAuction);
      setLiveAuction(dataAuction?.status);
      setIsOpen(false);

      if (dataAuction.current_stock_unit_id !== null) {
        // getDetailAuctionNextLot(dataAuction?.current_stock_unit_id);
        getDetailAuctionBiddingList(dataAuction?.current_stock_unit_id);
        getDetailAuctionUnit(dataAuction?.current_stock_unit_id);
      }
    } else {
      toast.error(response.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };
  const getDetailAuctionUnit = async (unit_id) => {
    // console.log('ini data units')
    const imageURL = [];
    const params = {};
    const response = await withoutAuthProvider.getDataWithoutAuth(`/api/v2/public/detail-auction/${id}/detail-unit/${unit_id}`, params);
    if (response?.status === 200) {
      const dataAuction = response.data.data;
      // console.log(dataAuction)
      setData(dataAuction);
      if (dataAuction.pictures.length > 0) {
        dataAuction.pictures.map((image, i) => imageURL.push({ image: image.image_url }));
        setImages(imageURL);
      }
      setIsOpen(false);
      setDataModal((prev) => ({
        ...prev,
        data_unit: dataAuction,
      }));

      if (dataAuction?.unit_auction_status === 'NOT_SOLD' || dataAuction?.unit_auction_status === 'SOLD') {
        setIsOpen(true);
        setResult('LOADING');
      }

      if (dataAuction?.unit_auction_status === 'NOT_SOLD') {
        console.log('unit not sold');
        setResult('TIDAK TERJUAL');
        // setIsOpen(true);
      } else if (dataAuction?.unit_auction_status === 'SOLD') {
        console.log('unit sold');
        setResult('TERJUAL');
        // setIsOpen(true);
      }
    } else {
      if (response?.status == 404) {
        // setLiveAuction("NOT_FOUND");
        // if (response.data.message === "Lelang belum dimulai") {
        //     setLiveAuction("PENDING")
        // } else {
        //     setLiveAuction("NOT_FOUND");
        // }
      } else {
        toast.error(response.data.message, {
          id: 'error-message',
          duration: 3000,
        });
      }
    }
  };
  // const getDetailAuctionNextLot = async (unit_id) => {
  //     const params = {}
  //     const response = await withoutAuthProvider.getDataWithoutAuth(`/api/v2/public/detail-auction/${id}/next-lot/${unit_id}`, params);
  //     if (response?.status === 200) {
  //         const dataAuction = response.data.data;
  //         // console.log('ini next lot', dataAuction)
  //         setNextLots(dataAuction.filter((a, b) => b <= 3));
  //     } else {
  //         toast.error(response.data.message, {
  //             id: 'error-message',
  //             duration: 3000
  //         });
  //     }
  // }
  const getDetailAuctionBiddingList = async (unit_id) => {
    const params = {};
    const response = await withoutAuthProvider.getDataWithoutAuth(`/api/v2/public/detail-auction/${id}/detail-bidder/${unit_id}`, params);
    if (response?.status === 200) {
      const dataAuction = response.data.data;
      // console.log('ini list bid', dataAuction)
      const dataList = dataAuction?.auction_progresses?.map((el) => ({
        ...el,
        offer_price: parseInt(el.offer_price),
      }));

      const time_diff = dataAuction?.time_diff || 0;
      // const timestamp = new Date(dataAuction?.timestamp_now).getTime();
      // const blocked = new Date(dataAuction?.blocked_at).getTime();

      // setTimer(prev => ({
      //     timestamp_now: timestamp,
      //     blocked_at: blocked,
      //     different: blocked - timestamp,
      // }));
      const last_price = parseInt(dataAuction?.last_price);
      let nextOffer = parseInt(dataAuction?.last_price);

      if (dataAuction.auction_progresses.length > 0) {
        nextOffer = parseInt(last_price) + parseInt(dataAuction?.unit_type?.multiple_bid);
      }
      // console.log(multipleBid)
      // console.log(nextOffer)
      const newData = dataList.filter((el, idx) => idx !== 0);
      localStorage.setItem('listBidder', JSON.stringify(newData));
      localStorage.setItem('highBid', JSON.stringify(dataList[0]));
      setBiddingList(newData);
      setHighestBid((prev) => ({
        ...prev,
        offer_price: last_price,
        type: dataList?.length > 0 ? dataList[0]?.type : 'Harga Dasar',
      }));
      setKey((prev) => prev + 1);
      setCounter(time_diff);
    } else {
      toast.error(response.data.message, {
        id: 'error-message',
        duration: 3000,
      });
    }
  };

  const handleLatestBid = (e) => {
    const dataAuction = e;
    const time_diff = dataAuction?.time_diff || 0;
    const auction_progress = e.auction_progress;
    const highBid = JSON.parse(localStorage.getItem('highBid') ?? '{}');
    var bidList = JSON.parse(localStorage.getItem('listBidder') ?? '[]');

    const found = bidList.some((v) => v.id === auction_progress.id);
    if (!found) {
      const last_price = parseInt(auction_progress?.offer_price);
      // let nextOffer = parseInt(auction_progress?.offer_price) + parseInt(dataAuction?.unit_type?.multiple_bid);
      const currBid = {
        buyer: {
          id: auction_progress?.id,
          buyer_id: auction_progress?.buyer_id,
          user_id: auction_progress?.buyer_user_id,
        },
        user_email: auction_progress?.buyer_email,
        user_name: auction_progress?.buyer_name,
        user_nipl_id: auction_progress?.buyer_nipl_id,
        offer_price: last_price,
        type: auction_progress?.type ? auction_progress?.type : 'Harga Dasar',
      };

      if (dataAuction.source === 'BID') {
        const currentHighest = highBid;
        // console.log('ini data highest', currentHighest)
        bidList.unshift(currentHighest);
      }
      // var datas = auction_progress
      // bidList.unshift(datas);
      // const newData = bidList.filter((el, idx) => idx !== 0);
      localStorage.setItem('listBidder', JSON.stringify(bidList));
      localStorage.setItem('highBid', JSON.stringify(currBid));

      setBiddingList(bidList);
      setHighestBid(currBid);
      // setHighestBid(prev => ({
      //     ...prev,
      //     buyer: {
      //         id: auction_progress?.id,
      //         buyer_id: auction_progress?.buyer_id,
      //         user_id: auction_progress?.buyer_user_id,
      //     },
      //     user_email: auction_progress?.buyer_email,
      //     user_name: auction_progress?.buyer_name,
      //     user_nipl_id: auction_progress?.buyer_nipl_id,
      //     offer_price: last_price,
      //     type: auction_progress?.type ? auction_progress?.type : 'Harga Dasar',
      // }))

      setKey((prev) => prev + 1);
      setCounter(time_diff);
    }
  };

  const secondsToTime = (secs) => {
    let divisor_for_minutes = secs % (60 * 60);
    let minutes = Math.floor(divisor_for_minutes / 60);

    let divisor_for_seconds = divisor_for_minutes % 60;
    let seconds = Math.ceil(divisor_for_seconds);

    return zeroPad(minutes) + ':' + zeroPad(seconds);
  };

  const callData = () => {
    getDetailAuction();
    // setHii(prev => prev + 1);
  };

  useEffect(() => {
    window.addEventListener('focus', callData);

    return () => {
      window.removeEventListener('focus', callData);
    };
  }, []);

  useEffect(() => {
    const timer = counter > 0 && setInterval(() => setCounter(counter - 1), 1000);

    if (counter === 0) {
      setDataModal((prev) => ({
        ...prev,
        lot_number: auction?.auction_lane_name || '',
      }));
      setResult('LOADING');
      setIsOpen(true);
      console.log('test');
    }

    return () => clearInterval(timer);
  }, [counter]);

  useEffect(() => {
    // const bearer = localStorage.getItem('token');
    window.Echo = new Echo({
      broadcaster: 'pusher',
      key: process.env.REACT_APP_WS_KEY,
      cluster: process.env.REACT_APP_WS_CLUSTER,
      forceTLS: false,
      authEndpoint: `${process.env.REACT_APP_URL}broadcasting/auth`,
      // auth: {
      //     headers: {
      //         Authorization: 'Bearer ' + bearer,
      //         Accept: 'application/json',
      //     }
      // }
    });

    window.Echo.channel(`auction-public.${id}`)
      .listen(`.auction.all`, (e) => {
        console.log('STATUS');
        console.log(e);
        getDetailAuction();
      })
      .listen(`.auction.bidder`, (e) => {
        console.log('ACTION');
        console.log(e);
        // getDetailAuctionBiddingList(e?.current_unit_auction_id);
        handleLatestBid(e);
      });

    return () => {
      console.log('stop listen');
      // stop listen
      // window.Echo.public(`auction.${id}`)
      //     .stopListening(`.auction.all`)
      //     .stopListening(`.auction.bidder`)
    };
  }, []);

  useEffect(() => {
    // getAuction();
    getDetailAuction();
  }, []);

  return (
    <>
      {liveAuction === 'STARTED' ? (
        <>
          <div className="container">
            <div className="d-flex py-3 h-100 flex-column gap-3" style={{ minHeight: '100vh' }}>
              <div className="box-lelang overflow-hidden ">
                <div className="d-flex px-3 align-items-center justify-content-between">
                  <div className="d-flex gap-4">
                    <div className="py-3">
                      <span className="lelang-title">{auction?.name || '-'}</span>
                    </div>
                    <div className="d-flex align-items-center gap-4">
                      <div className="lelang-time mb-0">
                        <Time />
                        <span>
                          {auction?.date_start && auction?.time_start
                            ? format(new Date(`${auction.date_start} ${auction.time_start}`), 'HH:mm')
                            : '-'}{' '}
                          -{' '}
                          {auction?.date_start && auction?.time_finish
                            ? format(new Date(`${auction?.date_start} ${auction?.time_finish}`), 'HH:mm')
                            : '-'}{' '}
                          WIB
                        </span>
                      </div>
                      <div className="lelang-location mb-0">
                        <Location />
                        <span>{auction?.location_name || '-'}</span>
                      </div>
                    </div>
                  </div>
                  <div className="h-100">
                    <img src={logo} alt="" style={{ height: '48px', width: '137px' }} />
                  </div>
                </div>
              </div>
              <div className="row flex-grow-1">
                <div className="col-md-7">
                  <div className="box-border p-3 h-100">
                    <div className="row">
                      <div className="col-6">
                        <div className="position-relative">
                          <CarouselLayout>
                            {images.length > 0 ? (
                              images.slice(0, 5).map((image, i) => (
                                <div key={'img' + i}>
                                  <div
                                    className="with-background detail-unit-slider"
                                    style={
                                      image.image
                                        ? {
                                            background: `url("${image.image}")`,
                                            height: '222px',
                                          }
                                        : { height: '222px' }
                                    }
                                  ></div>
                                </div>
                              ))
                            ) : (
                              <div>
                                <div
                                  className="with-background detail-unit-slider"
                                  style={
                                    defaultImage
                                      ? {
                                          background: `url(${defaultImage})`,
                                          height: '222px',
                                        }
                                      : { height: '222px' }
                                  }
                                ></div>
                              </div>
                            )}
                          </CarouselLayout>
                          <div className="grade-unit-auction">
                            Grade
                            <br />
                            <span className="grade-auction">{data?.unit_inspection?.grade || '-'}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="h-100">
                          <div>
                            <span className="nomor-lot mb-2" style={{ fontSize: '1.2rem' }}>
                              Lot {auction?.auction_lane_name && data?.lot_number ? auction?.auction_lane_name + data?.lot_number : '-'}
                            </span>
                            <div style={{ fontSize: '24px', fontWeight: 400 }}>{data?.unit_name || ''}</div>
                          </div>
                          <div>
                            <span className="unit-title mt-3">Harga Dasar</span>
                            <div className="fw-bold" style={{ fontSize: '24px' }}>
                              Rp. {data?.base_price !== undefined ? data?.base_price?.toLocaleString() : '0'}
                            </div>
                          </div>
                          <div>
                            <h2 className="unit-name-auction">{data?.unit_name || ''}</h2>
                            <span className="unit-title mt-3 mb-2">Highlight</span>
                            <div className="unit-highlight">
                              {data?.tags?.length > 0 ? data?.tags.map((row, i) => <span key={'tag' + i}>{row.name}</span>) : '-'}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="unit-title mb-2">Detail Kendaraan</span>
                      <div style={{ maxHeight: '230px', overflowY: 'auto' }}>
                        <div className="d-flex flex-wrap" style={{ gap: '8px' }}>
                          {data?.stock_informations?.length > 0
                            ? data?.stock_informations
                                ?.filter((el) => el.value !== '')
                                .map(({ label, value }, idx) => (
                                  <div className="informasi-unit-auction" key={`information-${idx + 1}`}>
                                    <span className="title mb-0">{label}</span>
                                    <span className="value">{value || '-'}</span>
                                  </div>
                                ))
                            : '-'}
                          {data?.stock_documents?.length > 0
                            ? data?.stock_documents
                                ?.filter((el) => el.value !== '')
                                .map(({ label, value }, idx) => (
                                  <div className="informasi-unit-auction" key={`information-${idx + 1}`}>
                                    <span className="title mb-0">{label}</span>
                                    <span className="value">{value || '-'}</span>
                                  </div>
                                ))
                            : '-'}
                        </div>
                      </div>
                      <div style={{ height: '135px' }}>
                        <div className="d-flex w-100 h-100" style={{ marginTop: '8px', paddingRight: '1.25rem' }}>
                          <div
                            className="d-flex flex-column align-items-start w-100"
                            style={{
                              padding: '8px',
                              border: '1px solid #DCDDE0',
                              borderRadius: '2px',
                            }}
                          >
                            <span
                              className="title mb-0"
                              style={{
                                fontWeight: 400,
                                fontSize: '12px',
                                lineHeight: '18px',
                                color: '#1E1E1F',
                              }}
                            >
                              Catatan
                            </span>
                            <span className="value">-</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="h-100">
                    <div className="bidder-list d-flex flex-column h-100 mt-0" style={{ marginTop: '20px' }}>
                      <div className="d-flex justify-content-between align-items-center p-3">
                        <div className="font-sm">
                          <span className="me-2" style={{ textAlign: 'right' }}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path
                                d="M20 22H4V20C4 18.6739 4.52678 17.4021 5.46447 16.4645C6.40215 15.5268 7.67392 15 9 15H15C16.3261 15 17.5979 15.5268 18.5355 16.4645C19.4732 17.4021 20 18.6739 20 20V22ZM12 13C11.2121 13 10.4319 12.8448 9.7039 12.5433C8.97595 12.2417 8.31451 11.7998 7.75736 11.2426C7.20021 10.6855 6.75825 10.0241 6.45672 9.2961C6.15519 8.56815 6 7.78793 6 7C6 6.21207 6.15519 5.43185 6.45672 4.7039C6.75825 3.97595 7.20021 3.31451 7.75736 2.75736C8.31451 2.20021 8.97595 1.75825 9.7039 1.45672C10.4319 1.15519 11.2121 1 12 1C13.5913 1 15.1174 1.63214 16.2426 2.75736C17.3679 3.88258 18 5.4087 18 7C18 8.5913 17.3679 10.1174 16.2426 11.2426C15.1174 12.3679 13.5913 13 12 13Z"
                                fill="#646566"
                              />
                            </svg>
                            <span className="ms-2">{auction?.nipls_count} Orang mengikuti bidding</span>
                          </span>
                        </div>
                        <span className="timer">
                          <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                              d="M9.99935 18.3337C14.6017 18.3337 18.3327 14.6027 18.3327 10.0003C18.3327 5.39795 14.6017 1.66699 9.99935 1.66699C5.39698 1.66699 1.66602 5.39795 1.66602 10.0003C1.66602 14.6027 5.39698 18.3337 9.99935 18.3337Z"
                              stroke="#C20102"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                            <path
                              d="M10 5V10L13.3333 11.6667"
                              stroke="#C20102"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                            />
                          </svg>
                          {secondsToTime(counter)}
                        </span>
                      </div>
                      <div className="header py-2">
                        <span className="title mb-0" style={{ textAlign: 'left' }}>
                          Harga Penawaran Saat Ini
                        </span>
                      </div>
                      <div className="highest-bid py-3" style={{ fontSize: '2rem' }}>
                        <span className="bid-value">{highestBid?.offer_price ? highestBid?.offer_price?.toLocaleString() : '-'}</span>
                        <span className="bidder">{highestBid?.type || 'Harga Dasar'}</span>
                      </div>
                      <div className="position-relative" style={{ maxHeight: '490px', overflowY: 'auto' }}>
                        {biddingList.length > 0 ? (
                          biddingList?.map((bid, index) => (
                            <div className={`bidder-item py-2`} key={`bid-${index}`}>
                              <span className="bid-value">Rp {bid?.offer_price.toLocaleString()}</span>
                              <span className="bidder">{bid?.type}</span>
                            </div>
                          ))
                        ) : (
                          <div className="bidder-item text-center font-sm text-secondary">
                            <span className="mx-auto">Belum ada yang penawaran</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="overflow-hidden w-100 d-flex align-items-center gap-3">
                                    <span className='lelang-title' style={{ textTransform: 'capitalize' }}>
                                        Lot<br />Selanjutnya
                                    </span>
                                    <div className='overflow-hidden w-100'>
                                        <div className='flex-grow-1 d-flex gap-3 overflow-auto'>
                                            {
                                                nextLots.length > 0 ?
                                                    nextLots.map((item, idx) => (
                                                        <div className='box-lelang col-6 col-md-4' key={`suggst-${idx}`}>
                                                            <div className='w-100 row overflow-hidden'>
                                                                <div className="col-md-5 pe-md-0 position-relative fav-img-container">
                                                                    <span className="position-absolute top-0 end-0 py-1 px-2 fw-semibold mt-2 me-4 me-md-2 font-sm text-primary bg-light rounded">
                                                                        Grade {item?.inspection?.grade || '-'}
                                                                    </span>
                                                                    <img src={item?.pictures?.length > 0 ? item?.pictures[0]?.image_url : defaultImage} style={{ objectFit: 'cover', borderTopLeftRadius: '12px', borderBottomLeftRadius: '12px', height: '151px' }} className='w-100' alt="" />
                                                                </div>
                                                                <div className="col-md-7 ps-md-0">
                                                                    <StampLabel text={`LOT A-${item?.lot_number}`} />
                                                                    <div className="p-2">
                                                                        <div className="py-2">
                                                                            <div className="text-secondary fw-bold mb-3">
                                                                                {item?.unit_name || '-'}
                                                                            </div>
                                                                            <div>
                                                                                {
                                                                                    item?.tags?.map((el, i) => <span className='value me-1' key={`${idx}-tag-${i}`}><span className='badge-status secondary'>{el?.name || '-'}</span></span>)
                                                                                }
                                                                            </div>
                                                                            <div className="fw-bold">Rp. {item?.base_price ? item?.base_price?.toLocaleString() : '-'}</div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                    : (
                                                        <div className="w-100 text-center">Tidak ada lot selanjutnya</div>
                                                    )
                                            }
                                        </div>
                                    </div>
                                </div> */}
            </div>
          </div>
          {isOpen ? <ResultLelang status={result} dataAuction={dataModal?.data_unit} /> : ''}
        </>
      ) : (
        ''
      )}
      {liveAuction === 'PENDING' || liveAuction === 'COMING' || liveAuction === 'CLOSED' ? <ArtworkPending status={liveAuction} /> : ''}
      {liveAuction === 'NOT_FOUND' ? <PageNotFound></PageNotFound> : ''}
    </>
  );
};

export default LiveAuctionPublic;
